import { render, staticRenderFns } from "./vippz.vue?vue&type=template&id=17f10934&scoped=true"
import script from "./vippz.vue?vue&type=script&lang=js"
export * from "./vippz.vue?vue&type=script&lang=js"
import style0 from "./vippz.vue?vue&type=style&index=0&id=17f10934&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f10934",
  null
  
)

component.options.__file = "vippz.vue"
export default component.exports