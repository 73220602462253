<template>
  <div class="box">
    <div class="jbxx">
      <div class="jbtop">
        <div class="icon"></div>
        <div class="tit">会员等级配置</div>
        <el-switch
          v-model="open"
          style="margin-left: 40px;"
          :validate-event="false"
          @change="openChange"
          :active-text="open?'启用':'停用'">
        </el-switch>
        <div></div>
      </div>
      <el-form class="jbxx-form" ref="form" v-for="(item,index) in vipList" :key="index" :model="item" label-width="180px">
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item :label="'等级'+(index+1)">
              <el-input v-model="item.LEVEL"></el-input>

            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="VIP年卡消费满">
              <el-input v-model="item.FULL"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="VIP生日积分倍数">
              <el-input v-model="item.IMES"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="VIP下单折扣">
              <el-input v-model="item.DISCOUNT"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="生日消费满">
              <el-input v-model="item.SRFULL"><template slot="append">赠送赠品</template></el-input>

            </el-form-item>
           
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="赠品礼品">
              <el-input v-model="item.LIFT"></el-input>
              <!-- <el-select v-model="LIFT" placeholder="请选择">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select> -->

            </el-form-item>

          </el-col>

        </el-row>
        <el-button v-if="index>0" class="delbtn" size="mini" type="info" @click="delLevel(index)" icon="el-icon-delete"></el-button>
      </el-form>
      <div class="addbtn" style="text-align: left;">
        <el-button size="medium" type="primary" @click="addlevel">添加等级</el-button>
      </div>
      <div class="butt" style="text-align: center;">
        <el-button size="medium" type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        LEVEL: '',
        FULL: '',
        TIMES: '',
        DISCOUNT: '',
        SRFULL: '',
        LIFT: ''

      },
      vipList:[
        {
          LEVEL: '',
          FULL: '',
          TIMES: '',
          DISCOUNT: '',
          SRFULL: '',
          LIFT: '',
        }
      ],
      tableData: [
        { name: 'wdz' }
      ],
      open: false,
      showDialog: false,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''

    };
  },
  created(){
  },
  mounted() {
    var that = this;
    that.$http.post("/setup/queryOne", {
      id: 8,
      setupType: 5,
    }).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.vipList = [
          {...response.data.data.setupContentJson}
        ]
        console.log('viplist',that.vipList)
      }
    });
  },
  methods: {
    add() {
      this.showDialog = true
    },
    addlevel(){
      this.vipList.push({
        LEVEL: '',
        FULL: '',
        TIMES: '',
        DISCOUNT: '',
        SRFULL: '',
        LIFT: '',
      })
    },
    async delLevel(index){
      var that = this
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        // return that.$notify.info({
        //   title: "提示",
        //   message: "已取消删除",
        //   showClose: true,
        // });
      } else {
        that.vipList.splice(index,1)
      }
    },
    openChange(val){
      console.log('openChange',val,this.open)
    },
    onSubmit() {
      var that = this;
      that.$http.post("/setup/update", {
        id: 8,
        setupType: 5,
        setupContent: that.vipList[0]
      }).then(function (response) {
        if (response.data.code == 200) {
          that.$message({
            type: 'success',
            message: '配置成功'
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
  padding-top: 20px;

  .jbxx {
    padding-right: 10px;
    .jbtop {
      height: 56px;
      background-color: #eef1f6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-left: 10px;
      margin-bottom: 40px;

      .icon {
        width: 6px;
        height: 19px;
        border-radius: 10px;
        background-color: #f77f1f;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
      }


    }
    .jbxx-form{
      padding: 10px 50px 10px 0;
      margin: 10px 20px;
      border: 1px solid #eef1f6;
      position: relative;
      .delbtn{
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }
.addbtn{
  margin: 10px 60px;
  .el-button{
    // font-size: 14px;
    // font-weight: bold;
  }
}
  .butt {
    // display: flex;
    // justify-content: space-between;
    padding: 50px 0px;
  }
}
</style>